.error {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100vw;
    height: 10vh;
    padding: 2rem;
    background-color: #fceea7;
    color: black;

    font-size: 1.5rem;
}

.error p {
  margin: 1vw;
}

.error div {
    padding: 3pt;

    border: black solid;
    border-radius: 5pt;
}

.white {
  background-color: azure;
  color: black;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  min-height: 100vh;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 10vh;
  min-width: 80vw;
}

.nav_left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  min-height: 10vh;
}
.nav_left a {
  padding: 16px;
  font-size: 2rem;
}

.nav_right {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  min-height: 10vh;
  cursor: pointer;
}
.nav_right a {
  padding: 4px;
  font-size: 2rem;
}

.ad_horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-width: 50vw;
  min-height: 10vh;
  background-color: azure;
  color: black;
  margin: 32px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 32px;
}

.content_two {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;

  margin-bottom: 32px;
  padding: 16px;
  border-style: solid;
  border-color: blue;
  border-radius: 16px;
}

.content_two div h2 {
  margin-top: 16px;
}


.content div {
  padding: 16px;
  margin: 16px;
}

.content div h2 {
  max-width: 20vw;
  margin: 16px;
  font-size: 2rem;
}

.content div p {
  max-width: 20vw;
  margin: 16px;
  font-size: 1rem;
}

.content div a {
  max-width: fit-content;
  max-height: fit-content;
  background-color: azure;
  color: black;
  border-radius: 16px;

  padding: 12px;
  margin: 16px;
}

.picture {
  max-width: 40vw;
  max-height: 50vh;
  background-color: blue;
}