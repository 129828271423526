.bet_position {
    position: relative;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.bet_description {
    font-size: 1.75rem;
    margin: 0.5rem;
}

.bet_stats_row {
    width: calc(100% - 1rem);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0.5rem;
}

.bet_options {
    text-decoration: underline;
    cursor: pointer;
}

.bet_banner {
    position: absolute;
    right: -0.75rem;
    top: 0;
    rotate: 45deg;
}

.bet_won {
    display: flex;
    flex-direction: column;
    justify-content: left;

    width: 100%;
    border-radius: 1rem;
    border-style: solid;
    border-color: #DAA520;

    margin-bottom: 1rem;
    padding: 0.25rem;
}

.bet_won .bet_banner {
    color: #DAA520;
}

.bet_lost {
    display: flex;
    flex-direction: column;
    justify-content: left;

    width: 100%;
    border-radius: 1rem;
    border-style: solid;
    border-color: azure;

    margin-bottom: 1rem;
    padding: 0.25rem;
}

.bet_lost .bet_banner {
    color: azure;
    top: -0.25rem;
    right: -0.9rem;
}

.bet_for {
    display: flex;
    flex-direction: column;
    justify-content: left;

    width: 100%;
    border-radius: 1rem;
    border-style: solid;
    border-color: #2E8B57;

    margin-bottom: 1rem;
    padding: 0.25rem;
}

.bet_for .bet_banner {
    color: #2E8B57;
}

.bet_against {
    display: flex;
    flex-direction: column;
    justify-content: left;

    width: 100%;
    border-radius: 1rem;
    border-style: solid;
    border-color: #B22222;

    margin-bottom: 1rem;
    padding: 0.25rem;
}

.bet_against .bet_banner {
    color: #B22222;
    top: -1rem;
    right: -1.8rem;
}

.bet_new {
    display: flex;
    flex-direction: column;
    justify-content: left;

    width: 100%;
    border-radius: 1rem;
    border-style: solid;
    border-color: blue;

    margin-bottom: 1rem;
    padding: 0.25rem;
}

.bet_new .bet_banner {
    color: blue;
}