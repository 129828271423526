.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 40vw;

    border-color: blue;
    border-radius: 16px;
    border-style: solid;
    padding: 16px;
}

.label {
    display: block;
    margin-bottom: 4px;
    font-size: 2rem;
    cursor: default;
}

.input,
.radio,
.select,
.button {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1.5rem;
}

.input[type="number"] {
    width: 100%;
}

.radio {
    margin-right: 10px;
}
.button {
    background-color: azure;
    color: black;
    border-radius: 16px;
    cursor: pointer;
    opacity: 1.0;
    filter: alpha(opacity=100);
}

.button:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.error_message {
    color: red;
    margin: .5rem;
    font-size: .7rem;
}