.content_group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  max-width: 1000px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5rem;
  min-height: 100vh;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 10vh;
  min-width: 80vw;
}

.nav_left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  min-height: 10vh;
}
.nav_left a {
  padding: 16px;
  font-size: 2rem;
}

.nav_right {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  min-height: 10vh;
  cursor: pointer;
}
.nav_right a {
  padding: 4px;
  font-size: 2rem;
}

.ad_vertical_left {
  position:fixed;
  overflow-x:hidden;
  justify-content: left;
  align-items: center;

  top: 20vh;
  left: 16px;
  min-width: 12vw;
  min-height: 70vh;
  background-color: azure;
  color: black;
}
.ad_vertical_right {
  position:fixed;
  overflow-x:hidden;
  justify-content: right;
  align-items: center;

  top: 20vh;
  right: 16px;
  min-width: 12vw;
  min-height: 70vh;
  background-color: azure;
  color: black;
}

.ad_horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-width: 50vw;
  min-height: 10vh;
  background-color: azure;
  color: black;
}

.content_row {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 1.5pt;
  border-radius: 1rem;

  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);

}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
  padding: 5pt;
  background-color: rgb(var(--background-start-rgb));

  margin: auto;
  width: 100%;
}

.content p {
  margin: 16px;
  font-size: 2rem;
  cursor: default;
}

.new_group {
  font-size: 1.2rem;
  cursor: pointer;
}

.content a {
  margin: 16px;
  font-size: 1.5rem;
}

.name {
  font-size: 4rem;
  text-transform: capitalize;
  cursor: default;
}

.stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-left: 32px;
  margin-right: 32px;

  cursor: default;
}

.stats p {
  margin: 16px;
  font-size: 2rem;
}

.stats div {
  margin: 16px;
  font-size: 2rem;
  cursor: pointer;
}

.guest {
    width: 100%;
  text-decoration: underline;
  cursor: pointer;
  padding: 5pt;
  margin: auto;
}