.error {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;
    padding: 2rem;
    background-color: #fceea7;
    color: black;

    font-size: 1.5rem;
}

.error div {
    margin: 1rem;
    padding: 3pt;

    border: black solid;
    border-radius: 5pt;
}

.mobile_nav {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  top: 0;
  padding: 2rem 2rem 0 2rem;

  background-color: rgb(var(--background-start-rgb));
    z-index: 1;
}

.nav_logo {
  font-size: 1.5rem;
}

.nav_list_container {
  position: fixed;
  left: 5vw;
  top: 5rem;
  width: 90vw;
  padding-bottom: 1pt;

  background-color: rgb(var(--background-start-rgb));
    z-index: 1;
}

.nav_list_container ul {
  list-style-type: none;
  display: grid;
  gap: 1rem;
}

.nav_list_container ul li {
  width: 100%;

  padding: 1pt;
  border-radius: 1rem;

  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.nav_list_container ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  border-radius: 1rem;
  padding: 5pt;
  background-color: rgb(var(--background-start-rgb));
}

.nav_list_container ul li a span {
  display: flex;
  font-size: 1.25rem;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin-top: 5rem;
  min-height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 1rem;
    padding: 5pt;
    background-color: rgb(var(--background-start-rgb));

    width: 100%;
}

.picture {
  width: 100%;
  background-color: blue;
}

.content div {
  padding: 0.5rem;
  margin: 0.5rem;
  width: 100%;
}

.content div h2 {
  font-size: 2rem;
}

.content div p {
  font-size: 1rem;
}

.content div a {
  display: flex;
  max-width: fit-content;
  max-height: fit-content;
  background-color: azure;
  color: black;
  border-radius: 1rem;

  padding: 0.75rem;
  margin: 1rem 0;
}

.content_two {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;

  margin-bottom: 1rem;
  padding: 2pt;

  border-radius: 1rem;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.content_two div {
  border-radius: 1rem;
  padding: 1rem;
  background-color: rgb(var(--background-start-rgb));
}

.content_two div h2 {
  margin-top: 1rem;
}

.content_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 1.5pt;
    border-radius: 1rem;

    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.content_group {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
}

.group_name {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 2.5rem;
    cursor: default;
}

.group_description {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    cursor: default;
}

.options {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding: 0.25rem;
}

.options div {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    width: 100%;
    padding: 0.25rem;
    margin-bottom: 0.5rem;

    font-size: 1rem;
    cursor: pointer;
}

.overlay_backgroud {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 2rem;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

.overlay_backgroud h1 {
    color: rgb(var(--foreground-rgb));
    font-size: 2rem;
    margin: 1rem;
}

.overlay_backgroud p {
    color: rgb(var(--foreground-rgb));
    font-size: 1rem;
    margin: 1rem;
}

.label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 1.5rem;
    cursor: default;
}

.input,
.radio,
.select {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    box-sizing: border-box;
    font-size: 1rem;
}

.share_link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    padding: 0.5rem;
    margin: 1rem;
    font-size: 1.25rem;

    background-color: azure;
    color: black;
    border-radius: 0.5rem;
    cursor: pointer;
    opacity: 1.0;
    filter: alpha(opacity=100);
}

.share_link:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.del_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0.5rem;
    margin: 1rem;
    font-size: 1rem;

    cursor: pointer;
    border-bottom: red solid 1pt;
}

.input[type="number"] {
    width: 100%;
}

.button {
    padding: 0.5rem;
    font-size: 1.25rem;
    margin-top: 0.5rem;
    width: 30%;

    background-color: azure;
    color: black;
    border-radius: 16px;
    cursor: pointer;
    opacity: 1.0;
    filter: alpha(opacity=100);
}

.button:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.login_form {
    width: 90%;
}
