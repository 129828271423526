.content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    max-width: 60vw;

    padding: 16px;
}

.group_name {
    display: block;
    margin-bottom: 4px;
    font-size: 4rem;
    cursor: default;
}

.group_description {
    display: block;
    margin-bottom: 4px;
    font-size: 2rem;
    cursor: default;
}

.options {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.options div {
    font-size: 1.5rem;
    margin: 32px;
    cursor: pointer;
}

.leaderboard_container {
    width: 100%;

    padding: 2pt;
    border-radius: 1.1rem;

    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.leaderboard {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;

    padding: 0.5rem;
    border-radius: 1rem;
    background-color: rgb(var(--background-start-rgb));
    color: rgb(var(--foreground-rgb));
}

.leaderboard li {
    margin-left: 32px;
    margin-bottom: 16px;
    font-size: 2rem;
}

.setting {
    width: fit-content;
    cursor: pointer;

    margin-bottom: 1rem;
}