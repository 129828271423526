.winner {
    cursor: pointer;
}

.bet_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.bet_buttons p {
    font-size: 1rem;
}

.overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0,0,0,0.5);
    cursor: default;
    z-index: 2;
}

.overlay_backgroud {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 36px;
    width: 40vw;

    background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
    border-style: solid;
    border-color: blue;
    border-radius: 16px;
}

.overlay_backgroud h1 {
    color: rgb(var(--foreground-rgb));
    font-size: 3rem;
    margin: 16px;
}

.overlay_backgroud p {
    color: rgb(var(--foreground-rgb));
    font-size: 1rem;
    margin: 16px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.label {
    display: block;
    margin-bottom: 4px;
    font-size: 2rem;
    cursor: default;
}

.input,
.radio,
.select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1.5rem;
}

.share_link {
    align-items: center;
    width: 50%;
    padding: 8px;
    font-size: 1.5rem;

    background-color: azure;
    color: black;
    border-radius: 16px;
    cursor: pointer;
    opacity: 1.0;
    filter: alpha(opacity=100);
    margin: 16px;
}

.share_link:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.del_button {
    align-items: center;
    width: auto;
    padding: 8px;
    font-size: 1rem;

    cursor: pointer;
    margin: 16px;
    border-bottom: red solid 1pt;
}

.input[type="number"] {
    width: 100%;
}

.radio {
    width: auto;
    margin: 10px;
}
.button {
    align-items: center;
    width: 30%;
    padding: 8px;
    font-size: 1.5rem;

    background-color: azure;
    color: black;
    border-radius: 16px;
    cursor: pointer;
    opacity: 1.0;
    filter: alpha(opacity=100);
}

.button:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.inline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}